import { takeEvery, put, delay, all, select } from 'redux-saga/effects'
import axios from 'axios'
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_REGISTER_AND_LOGIN,
  AUTH_LOGIN_APPLE,
  AUTH_LOGIN_APPLE_FAILURE,
} from './constants'
import { APIRoute } from '../../config'
import { FETCH_CURRENCIES } from '../payments/constants'
import { loggedIn, PaymentStep, paymentStepChanged, teamMemberAdded, userFieldUpdated } from '../signup/signupSlice'
import { PlayerHcpSource } from '@app/utils/enums'

const getOrganizationId = (state: StoreState) =>
  state.tournamentReducer.tournamentSite.tournament.tournamentOrganization.id

function* maybeLoadCurrencies() {
  const tournamentSite: TournamentSite = yield select((state: StoreState) => state.tournamentReducer.tournamentSite)
  const currencies = yield select((state: StoreState) => state.paymentReducer.currencies)
  if (tournamentSite.paymentEnabled && currencies.length === 0) {
    yield put({ type: FETCH_CURRENCIES })
  }
}

function* updateTeamPlayers(user: GGBUser) {
  const tournamentSite: TournamentSite = yield select((state: StoreState) => state.tournamentReducer.tournamentSite)

  yield put(userFieldUpdated({ fieldName: 'hcp', value: user.hcp }))

  if (!tournamentSite.allowPostponePayment) {
    yield put(paymentStepChanged({ step: PaymentStep.productsList }))
  }

  if (tournamentSite.tournament.isTeamFormat) {
    // Add team member to signupSlice
    const captain = {
      userId: Number(user.userId),
      firstName: user.firstName,
      lastName: user.lastName,
      hcp: user.hcp,
      hcpSource: PlayerHcpSource.UNCHANGED,
      email: user.email,
      gender: user.gender,
      clubName: user.club,
      profileImageUrl: user.avatarUrl,
      teamPlayerOrder: 1,
    }

    yield put(teamMemberAdded({ teamMember: captain }))
  }
}

function* doRegisterAndLogin({ payload }: RegisterAndLoginAction) {
  try {
    const organizationId: number = yield select(getOrganizationId)

    const payloadToSend = { ...payload, organizationId: organizationId }

    const res = yield axios.post(APIRoute.POST_CORE_REGISTER_AND_LOGIN(), payloadToSend)

    yield updateTeamPlayers(res.data)
    yield maybeLoadCurrencies()
    yield put({
      type: AUTH_LOGIN_SUCCESS,
      payload: {
        ...res.data,
      },
    })
    payload.onComplete()
  } catch (error) {
    console.log(`Register and login ${payload.email} requested - ${error}`)
    let errorMessage = error.toString()
    if (error.response && error.response.data.error.message) {
      errorMessage = error.response.data.error.message
    }
    yield put({
      type: AUTH_LOGIN_FAILURE,
      error: errorMessage,
    })
  }
}

function* doLogin(action: LoginAction) {
  try {
    const organizationId: number = yield select(getOrganizationId)

    const payloadToSend = {
      username: action.payload.username,
      password: action.payload.password,
      organizationId: organizationId,
      hcpRound: action.payload.hcpRound,
    }

    const res = yield axios.post(APIRoute.POST_CORE_LOGIN(), payloadToSend)

    yield updateTeamPlayers(res.data)
    yield maybeLoadCurrencies()
    yield put({
      type: AUTH_LOGIN_SUCCESS,
      payload: {
        ...res.data,
      },
    })
    if (action.payload.onComplete) {
      action.payload.onComplete()
    }
    yield put(loggedIn())
  } catch (error) {
    console.log(`Login ${action.payload.username} requested - ${error}`)
    if (action.payload.onComplete) {
      action.payload.onComplete(error)
    }
    yield put({
      type: AUTH_LOGIN_FAILURE,
      error: error.toString(),
    })
  }
}

function* doAppleLogin(action: AppleLoginAction) {
  try {
    const organizationId: number = yield select(getOrganizationId)

    const payloadToSend = { ...action.payload, organizationId }

    const res = yield axios.post(APIRoute.POST_CORE_LOGIN_APPLE(), payloadToSend)

    yield updateTeamPlayers(res.data)
    yield maybeLoadCurrencies()
    yield put({
      type: AUTH_LOGIN_SUCCESS,
      payload: {
        ...res.data,
      },
    })
    yield put(loggedIn())
  } catch (error) {
    console.log(`Apple login failed - ${error}`)

    yield put({
      type: AUTH_LOGIN_APPLE_FAILURE,
      error: error.toString(),
    })
  }
}

function* doLogout() {
  yield delay(1500)
  yield put({
    type: AUTH_LOGOUT_SUCCESS,
  })
}

export function* watchAuthentication() {
  yield all([
    takeEvery(AUTH_LOGIN, doLogin),
    takeEvery(AUTH_LOGIN_APPLE, doAppleLogin),
    takeEvery(AUTH_LOGOUT, doLogout),
    takeEvery(AUTH_REGISTER_AND_LOGIN, doRegisterAndLogin),
  ])
}
